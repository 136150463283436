<template>
	<div class="live-chat">
		<div class="chat-wrapper-event" id="chat-wrapper" @scroll="onChatScroll">
				<!-- Fetching -->
			<div class="p3 text-center" v-if="isFetching">
				<div class="is-loading large dark"></div>
			</div>
			<vx-card class="chat-event">
				<!-- List -->
				<div class="chat-item-event flex mb-4" v-for="(chat, index) in chats" :key="index">
					<div @click="selectedChat(chat.id)" class="chat-content">
						<h5 class="name-chat">{{chat.user_name}}</h5>
						<vs-row>
							<vs-button v-if="chat.id === selectedItemId && chat.__meta__.is_banned === 0" @click="banUser(chat)" class="chat-btn ml-2 mt-1 small icon-card-actions" color="#4991DF" icon-pack="feather" radius icon="icon-user-x"></vs-button>
							<vs-button v-if="chat.id === selectedItemId && chat.__meta__.is_banned === 1" @click="unbanUser(chat)" class="chat-btn ml-2 mt-1 small icon-card-actions" color="red" icon-pack="feather" radius icon="icon-user-check"></vs-button>
							<vs-button v-if="chat.id === selectedItemId" @click="deleteData(chat)" class="chat-btn ml-2 mt-1 small icon-card-actions" color="#4991DF" icon-pack="feather" radius icon="icon-trash"></vs-button>
							<div class="space-unactive" v-if="chat.id !== selectedItemId"></div>
							<div class="chat-message wrapper-chat-action" v-if="chat.id !== selectedItemId">{{ chat.content }}</div>
							<div class="chat-message wrapper-chat-action" v-if="chat.id === selectedItemId" :class="{ chatMessage: chatSwitchEditableColor === 'chatMessage' }">{{ chat.content }}</div>
						</vs-row>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getAxiosErrorMessage, duplicateVar,
} from '@/lib/helper';
import chatsApi from '@/api/chat';
import eventsApi from '@/api/event';

export default {
  name: 'EventQuestion',
  components: {
  },
  data() {
    return {
      event: null,
      chats: [],
      isFetching: false,
      userId: '',
      selected: [],
      dataModearated: false,
      selectedItemId: null,
      userName: '',
      isAllFetched: false,
      lastChat: null,
      page: 1,
      limit: 25,
      order_by: 'created_at',
      sort_by: 'desc',
      chatSwitchEditableColor: '',
      eventData: null,
      chatId: '',
    };
  },
  sockets: {
    connect() {
      console.log('connected');
      this.initSocket();
    },
    chat_create(payload) {
      this.chats.push(payload);
    },
    chat_delete(payload) {
      const index = this.chats.findIndex(curr => curr.id === payload);
      if (index !== -1) {
        this.chats.splice(index, 1);
      }
    },
    chat_update(payload) {
      const index = this.chats.findIndex(curr => curr.id === payload.id);
      Object.assign(this.chats[index], payload);
      for (let index = 0; index < this.chats.length; index++) {
        if (this.chats[index].user_id === payload.user_id) {
          this.chats[index].__meta__.is_banned = payload.__meta__.is_banned;
        }
      }
    },
  },
  methods: {
    selectedChat(chat) {
      this.chatSwitchEditableColor = 'chatMessage';
      this.selectedItemId = chat;
    },
    initSocket() {
      const payload = {
        room_id: this.room,
      };
      // Join Room
      this.$socket.client.emit('join', payload);
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    fetchList() {
      const params = {
        event_slug: this.eventSlug,
        page: this.page,
        limit: this.limit,
        order_by: this.order_by,
        sort_by: this.sort_by,
      };
      if (this.lastChat) params.older_than = this.lastChat.created_at;
      this.isFetching = true;
      const callback = (response) => {
        const chats = response.data;
        if (chats.length < this.limit) {
          this.isAllFetched = true;
        }
        for (let index = 0; index < chats.length; index++) {
          const chat = chats[index];
          if (this.chats.length === 0 && index === 0) {
            this.lastChat = duplicateVar(chat);
          }
          this.chats.unshift(chat);
        }
        this.page++;
        if (params.page === 1) {
          setTimeout(() => {
            this.scrollElemToBottom('chat-wrapper');
          }, 150);
        }
        this.isFetching = false;
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('Chat'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
      };
      chatsApi.list(params, callback, errorCallback);
    },
    scrollElemToBottom(id) {
      const elem = document.getElementById(id);
      if (elem) {
        const scrollHeight = elem.scrollHeight;
        elem.scrollTop = scrollHeight;
      }
    },
    onChatScroll(event) {
      const scrollTop = event.target.scrollTop;
      if (scrollTop === 0 && !this.isFetching && !this.isAllFetched) {
        this.fetchList();
      }
    },
    deleteData(item) {
      this.isDetailsVisible = false;
      this.selectedItem = duplicateVar(item);
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure want to delete this Chat?'),
        accept: this.deleteRecord,
        acceptText: this.$t('Delete'),
      });
    },
    deleteRecord() {
      this.$vs.loading();
      const item = this.selectedItem;
      const title = this.$t('Chat');
      const callback = (response) => {
        this.remove(item.id);
        const message = response.message;
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.$vs.loading.close();
      };
      chatsApi.delete(item.id, callback, errorCallback);
    },
    remove(itemId) {
      const index = this.chats.findIndex(({ id }) => id === itemId);
      if (index !== -1) {
        this.chats.splice(index, 1);
      }
    },
    fetch() {
      this.$vs.loading();
      this.isFetching = true;
      const callback = (response) => {
        const chats = response.data;
        this.chats = chats;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      this.$vs.loading.close();
      const errorCallback = () => {
        this.isFetching = false;
        this.eventNotFound = true;
        this.$vs.loading.close();
      };
      const params = {
        event_slug: this.eventSlug,
        limit: this.limit,
      };
      if (this.lastChat) params.older_than = this.lastChat.created_at;
      chatsApi.list(params, callback, errorCallback);
    },
    fetchEvent() {
      this.$vs.loading();
      this.isFetching = true;
      const callback = (response) => {
        const event = response.data;
        this.eventData = event;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      this.$vs.loading.close();
      const errorCallback = () => {
        this.isFetching = false;
        this.eventNotFound = true;
        this.$vs.loading.close();
      };
      eventsApi.getBySlug(this.eventSlug, callback, errorCallback);
    },
    banUser(chat) {
      this.userId = chat.user_id;
      this.chatId = chat.id;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Banned',
        text: this.$t('Are you sure want to Banned this User?'),
        accept: this.acceptBan,
      });
    },
    acceptBan() {
      const callback = () => {
        this.$vs.notify({
          color: 'success',
          title: 'Banned',
          text: this.$t('Banned User was successfully'),
        });
      };
      const errorCallback = () => {
        this.$vs.notify({
          color: 'error',
          title: 'Banned',
          text: this.$t('Banned User was failed'),
        });
      };
      const params = {
        chat_id: this.chatId,
        event_id: this.eventData.id,
        user_id: this.userId,
      };
      chatsApi.banChat(params, callback, errorCallback);
    },
    unbanUser(chat) {
      this.userId = chat.user_id;
      this.chatId = chat.id;
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Unbanned',
        text: this.$t('Are you sure want to Unbanned this User?'),
        accept: this.acceptunBan,
      });
    },
    acceptunBan() {
      const callback = () => {
        this.$vs.notify({
          color: 'success',
          title: 'Unbanned',
          text: this.$t('Unbanned User was successfully'),
        });
      };
      const errorCallback = () => {
        this.$vs.notify({
          color: 'error',
          title: 'Unbanned',
          text: this.$t('Unbanned User was failed'),
        });
      };
      const params = {
        chat_id: this.chatId,
        event_id: this.eventData.id,
        user_id: this.userId,
      };
      chatsApi.banChat(params, callback, errorCallback);
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    eventSlug() {
      return this.$route.params.eventSlug;
    },
    room() {
      const room = `event-${this.eventSlug}`;
      return room;
    },
  },
  watch: {
    eventSlug() {
      if (this.eventSlug) this.initSocket();
    },
  },
  created() {
    this.fetchEvent();
    this.fetchList();
    this.initSocket();
  },
  beforeDestroy() {
    this.leaveSocketRoom();
  },
  destroyed() {
  },
};
</script>
<style>
.name-chat{
	margin-left: 80px;
}
.wrapper-chat-action{
	margin-left: 0.5em;
}
.space-unactive{
	width: 70px
}
.chat-event{
	background-color: #F8F8F8;
}
.chat-btn.vs-button{
    border-radius: 1.5rem!important;
}
.chatMessage{
	background-color: #4991DF!important;
	color: white!important;
}
</style>
